import React, { useEffect } from "react";
import "./Footer.css";
import { FaFacebookF } from "react-icons/fa";
import { TiSocialInstagram, TiSocialLinkedin } from "react-icons/ti";

import aos from "aos";
import "aos/dist/aos.css";

function Footer() {
  useEffect(() => {
    aos.init({ duration: 2000 });
  });
  function instagram() {
    window.open("https://www.instagram.com/ahmed_dev13/", "_blank");
  }
  function facebook() {
    window.open(
      "https://web.facebook.com/profile.php?id=100093650314712",
      "_blank"
    );
  }
  function linkdIn() {
    window.open("https://www.linkedin.com/in/ahmed-dev13/", "_blank");
  }
  return (
    <footer>
      <div className="container">
        <div className="logo-right">
          <div className="logo" onClick={instagram}>
            Ahmed
          </div>
          <span>
            AllRight Reserved -<span id="current-year">2023</span>{" "}
          </span>
        </div>
        <div className="social-media">
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            className="social-card"
            onClick={facebook}
          >
            <FaFacebookF className="icon" />
            Facebook
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="2500"
            className="social-card"
            onClick={instagram}
          >
            <TiSocialInstagram className="icon" />
            Instagram
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="3000"
            className="social-card"
            onClick={linkdIn}
          >
            <TiSocialLinkedin className="icon" />
            LinkdIn
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
